function host() {
    if ((process.env.NODE_ENV == "development")) {
        //开发环境
        return "http://www.handebook.com:81";
    } else {
        //生产地址
        if (window.location.protocol == 'https:') {
            return "https://www.handebook.com";
        }
        else {
            return "http://www.handebook.com";
        }
    }
}

export default {
    document: {
        download: `${host()}/api/web/document/download`,
        info: `${host()}/api/web/document/info`,
        password: `${host()}/api/web/document/password`,
        purchased: `${host()}/api/web/document/purchased`,
    },
}