<template>
  <div class="footer-container">
    <div class="footer-item">
      <span class="footer-text">鲁ICP备15004118号-6</span>
      <span class="footer-line"></span>
      <span class="footer-text">(署)网出证(鲁)字第009号</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterBarForQingstudy",
  components: {
  },
  props: {
  },
  data() {
    return {};
  },
  methods: {

  },
};
</script>

<style scoped>
.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer-item {
  margin-bottom: 10px;
}

.footer-text {
  font-weight: 400;
  color: #000;
  font-size: 14px;
}

.footer-line {
  content: " ";
  height: 15px;
  border-left: 1px solid #999;
  display: inline-block;
  vertical-align: sub;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 2px;
}
</style>
