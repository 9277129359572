<template>
  <div class="viewer-box">
    <iframe :src="src" class="viewer-iframe"></iframe>
    <div v-if="loading" class="viewer-process">
      <el-progress type="circle" :percentage="percentage"></el-progress>
      <div class="viewer-text">正在加载文档，请稍等...</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DocumentViewer",
  components: {
  },
  props: {
    img: {
      type: String,
      default: require('@/assets/images/pc/logo.png'),
    },
    loading: {
      type: Boolean,
      default: true,
    },
    percentage: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      src: null,
    };
  },
  methods: {
    open(pdfid, pdfuri, pdfpaw) {
      //框架跳转
      this.src = `pdfjs/web/viewer.html?pdfid=${pdfid}&pdfuri=${pdfuri}&pdfpaw=${pdfpaw}`;
    },
  },
};
</script>

<style scoped>
.viewer-box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.viewer-process {
  position: absolute;
}

.viewer-text {
  color: gray;
  font-size: 14px;
}

.viewer-iframe {
  width: 100%;
  height: 100%;
  border: 0;
  /*解决iframe导致出现滚动条：iframe后边有个看不见、摸不着的行内空白节点， 空白节点占据着高度，iframe与空白节点的基线对齐，导致了div被撑开，从而出现滚动条*/
  vertical-align: top;
}
</style>
